
import { defineComponent, PropType } from "vue";
import SecurityLog from "@/components/User/AppSecurity/SecurityLog.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import ConnectedSocial from "@/components/User/ConnectedSocial.vue";
import { IUserLoginHistory } from "@/store/interfaces/accounts/IUserAccount";
import HeadToolBar from "@/components/Table/HeadToolBar.vue";

const tabs = [
  {
    id: 1,
    title: "Log In Logs"
  },
  {
    id: 2,
    title: "Password Change"
  },
  { id: 3, title: "Connected Socials" }
];

export default defineComponent({
  name: "AppSecurity",
  components: {
    HeadToolBar,
    ConnectedSocial,
    SvgIcon,
    AppButton,
    SecurityLog
  },
  props: {
    loginHistory: {
      type: Object as PropType<IUserLoginHistory[]>,
      required: true
    }
  },
  data() {
    return {
      selectedTab: tabs?.[0] as any,
      tabs
    };
  },
  computed: {
    mapedloginHistory() {
      if (this.loginHistory) {
        return this.loginHistory.map(login => ({
          deviceName: login.device,
          deviceOS: login.client,
          deviceType: login.client === "web" ? "desktop" : "phone",
          connectLocation: login.clientIP,
          connectDate: login.createdAt
        }));
      } else {
        return {};
      }
    },
    tabsList() {
      return this.tabs.map(tab => tab.title);
    }
  },
  methods: {
    changeTab(tabIdx: number): void {
      this.selectedTab = tabs[tabIdx];
    }
  }
});
