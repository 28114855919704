<template>
  <div class="content-box px-0 translation-packages">
    <div class="padding-inner py-0">
      <h2>Translation Packages</h2>
    </div>

    <div class="mt-3 pt-3">
      <tabs v-model="selectedTab">
        <div class="padding-inner py-0 d-flex align-items-end">
          <tab
            v-for="(tab, i) in tabs"
            :key="`t${i}`"
            class="tab"
            :val="tab"
            :label="tab"
            :indicator="true"
          />
        </div>
      </tabs>
    </div>

    <div class="pt-4 overlay-overflow">
      <tab-panels v-model="selectedTab" :animate="true">
        <tab-panel :val="tabs[0]">
          <transition name="fade">
            <div
              v-if="selectedTab === tabs[0]"
              class="padding-inner w-100 py-0"
            >
              <div class="w-100 d-flex justify-content-between flex-wrap">
                <div class="user-balance-wrapper">
                  <h3 class="balance">980,000</h3>
                  <strong class="xl"><b>available characters</b></strong>
                </div>

                <Btn size="smL" type="secondary" class="mt-2">
                  <BtnContent label="Add Package" icon="plus" />
                </Btn>
              </div>

              <div class="mt-5 pb-4">
                <div class="row">
                  <div class="col-7">
                    <PackageProgress
                      :count="0"
                      :total="500"
                      :title="
                        `<strong><b>0</b> characters used <br> out of <b>500, 000</b> </strong>`
                      "
                      :description="
                        `<span class='mt-2 d-block'>
                        One-time payment <br/>
                        Purchased on 17.09.2020 at 8:23 am GMT
                      </span>`
                      "
                    />
                  </div>
                  <div class="col-5 d-flex justify-content-end pt-3">
                    <Btn type="danger-transparent" size="sm">
                      Cancel Package
                    </Btn>
                  </div>

                  <div class="pt-2">
                    <hr class="w-100 mt-3" />
                  </div>
                </div>

                <div class="mt-2">
                  <div class="row">
                    <div class="col-7">
                      <PackageProgress
                        :count="810"
                        :total="1000"
                        :title="
                          `<strong><b>810</b> characters used <br> out of <b>1, 000, 000 </b> </strong>`
                        "
                        :description="
                          `<span class='mt-2 d-block'>
                            One-time payment <br/>
                            Purchased on 17.09.2020 at 8:23 am GMT
                          </span>`
                        "
                      />
                    </div>
                    <div class="col-5 d-flex justify-content-end pt-3">
                      <Btn type="danger-transparent" size="sm">
                        Cancel Package
                      </Btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </tab-panel>
        <tab-panel :val="tabs[1]">
          <transition name="fade">
            <div
              v-if="selectedTab === tabs[1]"
              class="padding-inner w-100 py-0"
            >
              <div class="w-100">
                <div class="w-100">
                  <PackageProgress
                    :count="500"
                    :total="500"
                    :title="
                      `<strong><b>500, 000</b> characters used <br> out of <b>500, 000</b> </strong>`
                    "
                    :description="
                      `<span class='mt-2 d-block'>
                        One-time payment <br/>
                        Purchased on 17.09.2020 at 8:23 am GMT
                      </span>`
                    "
                  />

                  <hr class="w-100 mt-3" />
                </div>
                <div class="mt-4 w-100">
                  <PackageProgress
                    :count="500"
                    :total="500"
                    :title="
                      `<strong><b>1,000,000</b> characters used <br> out of <b>1,000,000</b> </strong>`
                    "
                    :description="
                      `<span class='mt-2 d-block'>
                        One-time payment <br/>
                        Purchased on 17.09.2020 at 8:23 am GMT
                      </span>`
                    "
                  />
                </div>
              </div>
            </div>
          </transition>
        </tab-panel>
      </tab-panels>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/ui/Buttons/Button";
import BtnContent from "@/components/ui/Buttons/BtnContent";
import PackageProgress from "@/components/ui/Progress/PackageProgress";

const tabs = ["Active", "Expired"];
export default {
  name: "TranslationPackages",
  components: { PackageProgress, BtnContent, Btn },
  data() {
    return { selectedTab: tabs[0], tabs };
  }
};
</script>

<style lang="scss">
.user-balance-wrapper + .button {
  width: max-content;
  min-width: max-content;
  padding-left: 1.4em;
  padding-right: 1.4em;
}
.translation-packages {
  min-height: 520px;
  .package-progress-description {
    strong {
      font-size: 13px;
    }
    .secondary-text {
      line-height: 1.2;
      font-size: 10px;
    }
  }
  .package-progress:not(:last-child) {
    .package-progress-description {
      //border-bottom: 1px solid $border-color;

      padding-bottom: 1.5em;
    }
  }
}
</style>
