<template>
  <div class="virtual-numbers-list-table user-table">
    <TableView class="accordion-table">
      <template #table-head>
        <TableHeadCell id="virtual-number:number" name="NUMBER" size="md" />
        <TableHeadCell id="virtual-number:label" name="Label" size="md" />
        <TableHeadCell
          id="virtual-number:usage-area"
          name="USAGE AREA"
          size="md"
        />
        <TableHeadCell id="virtual-number:price" name="PRICE" size="lg" />
        <TableHeadCell
          id="virtual-number:expire-date"
          name="EXPIRE DATE"
          size="sm"
        />
        <TableHeadCell
          id="virtual-number:line-status"
          name="LINE STATUS"
          size="md"
          class="right-align"
        />
      </template>
      <template #table-body>
        <VirtualNumbersTableRow
          v-for="(n, idx) in 15"
          :key="n"
          :idx="idx"
          @openLogsModal="id => $emit('openLogsModal', id)"
        />
      </template>
    </TableView>
  </div>
</template>

<script>
import TableView from "@/components/Table/TableView";
import TableHeadCell from "@/components/Table/TableHeadCell";

import VirtualNumbersTableRow from "@/components/User/VirtualNumbers/VirtualNumbersTableRow";

export default {
  name: "VirtualNumbersList",
  components: {
    VirtualNumbersTableRow,
    TableHeadCell,
    TableView
  },
  emits: ["openLogsModal"]
};
</script>

<style lang="scss">
.user-table {
  .pseudo-table--head {
    border-width: 2px;

    .pseudo-table-row {
      padding-bottom: 0.857rem;
      padding-left: $content-box-x;
      padding-right: $content-box-x;
    }
  }
}

.withMoreMenu.accordion-table {
  .pseudo-table--body {
    padding-left: $content-box-x;
    padding-right: $content-box-x;
  }
}
</style>
