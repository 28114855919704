<template>
  <TableRow class="user-table-row" :class="`payment-type-${paymentDirection}`">
    <template #content>
      <Accordion>
        <template #heading>
          <div
            class="d-flex align-items-center justify-content-between accordion-header pe-3"
          >
            <TableCell :size="colsSizes[0]">
              <div class="d-flex align-items-center">
                <div :class="`icon-circle icon-circle-${iconBg} me-2`">
                  <SvgIcon :icon="icon" />
                </div>

                <strong>{{ date }}</strong>
              </div>
            </TableCell>

            <TableCell :size="colsSizes[1]">
              <span v-html="description" />
            </TableCell>
            <TableCell :size="colsSizes[2]">
              <span v-html="type" />
            </TableCell>
            <TableCell :size="colsSizes[3]">
              <strong>{{ id }}</strong>
            </TableCell>
            <TableCell :size="colsSizes[4]">
              <strong class="payment-amount">
                <b>{{ amount }}</b>
              </strong>
            </TableCell>

            <TableCell :size="colsSizes[5]">
              <AppBadge
                :rounded="false"
                class="ms-auto"
                :type="badge.type"
                size="md"
              >
                {{ badge.text }}
              </AppBadge>
            </TableCell>
          </div>
        </template>
        <template #body>
          <div class="table-accordion-body pt-4">
            <div class="row mb-1">
              <div class="col-6 d-flex">
                <Card title="TRANSACTION details">
                  <form class="pb-4" @submit.prevent>
                    <div class="row">
                      <div class="col-12">
                        <AutosizeTextarea
                          :id="p_id"
                          v-model="detailsDescription"
                          readonly="true"
                          input-type="material"
                          label="description"
                        />
                      </div>
                      <div class="col-6 mt-3">
                        <AppInput
                          :id="`details:${id}-status`"
                          v-model="detailsStatus"
                          label="Status"
                          readonly="true"
                          input-type="material"
                        />
                      </div>
                      <div class="col-6 mt-3">
                        <AppInput
                          :id="`details:${id}-recipient-id`"
                          v-model="detailsRecipientId"
                          label="Recipient ID"
                          readonly="true"
                          input-type="material"
                        />
                      </div>

                      <div class="col-6 mt-3">
                        <AppInput
                          :id="`details:${id}-amount`"
                          v-model="detailsAmount"
                          label="Amount"
                          readonly="true"
                          input-type="material"
                        />
                      </div>

                      <div class="col-6 mt-3">
                        <AppInput
                          :id="`details:${id}-code`"
                          v-model="detailsAuthorizationCode"
                          label="Authorization Code"
                          readonly="true"
                          input-type="material"
                        />
                      </div>
                      <div class="col-6 mt-3">
                        <AppInput
                          :id="`details:${id}-type`"
                          v-model="detailsPaymentType"
                          label="Payment Type"
                          readonly="true"
                          input-type="material"
                        />
                      </div>

                      <div class="col-6 mt-3">
                        <AppInput
                          :id="`details:${id}-transaction-id`"
                          v-model="detailsTransactionId"
                          label="Transaction ID"
                          readonly="true"
                          input-type="material"
                        />
                      </div>

                      <div class="col-6 mt-3">
                        <AppInput
                          :id="`details:${id}-date`"
                          v-model="detailDateOfPayment"
                          label="Date of Payment"
                          readonly="true"
                          input-type="material"
                        />
                      </div>
                    </div>
                  </form>
                </Card>
              </div>
              <div class="col-3 d-flex">
                <Card title="Payment owner">
                  <UserInfo
                    class="w-100"
                    name="John Beggins"
                    description="Operator: 00000871"
                  />

                  <form class="mt-5" @submit.prevent>
                    <div>
                      <AppInput
                        :id="`owner:${id}-source`"
                        v-model="owner_source"
                        label="Source"
                        readonly="true"
                        input-type="material"
                      />
                    </div>

                    <div class="mt-3">
                      <AppInput
                        :id="`owner:${id}-code`"
                        v-model="owner_confirmation_code"
                        label="Confirmation code"
                        readonly="true"
                        input-type="material"
                      />
                    </div>

                    <div class="mt-3">
                      <AutosizeTextarea
                        :id="`owner:${id}-note`"
                        v-model="owner_note"
                        readonly="true"
                        input-type="material"
                        label="Note"
                      />
                    </div>
                  </form>
                </Card>
              </div>

              <div class="col-3">
                <Card v-if="status !== 'rejected'" title="Refund">
                  <form class="pb-4" @submit.prevent>
                    <div>
                      <AppInput
                        :id="`refund-${id}-amount`"
                        v-model="refund_amount"
                        input-type="material"
                        :show-controls="true"
                        label="amount"
                        type="number"
                      />
                    </div>

                    <div class="mt-3">
                      <AppInput
                        :id="`refund-${id}-reason`"
                        v-model="refund_reason"
                        :is-textarea="true"
                        input-type="material"
                        label="Return reason"
                        type="number"
                        rows="5"
                      />
                    </div>

                    <div class="mt-3">
                      <Btn size="lg" class="w-100" type="danger">
                        Refund Payment
                      </Btn>
                    </div>
                  </form>
                </Card>
                <Card v-else title="Refund">
                  <TextErrorMessage
                    message="Card authorization error. Error code - 4."
                  />

                  <div class="mt-4 pb-4">
                    <Btn type="danger" size="lg" class="w-100">
                      Send Receipt
                    </Btn>
                    <Btn type="primary" size="lg" class="mt-2 w-100">
                      Error Descriptions
                    </Btn>
                  </div>
                </Card>
              </div>
            </div>

            <div class="py-4">
              <span class="secondary-text">Balance after payment – $14</span>
            </div>
          </div>
        </template>
      </Accordion>
    </template>
  </TableRow>
</template>

<script>
import TableRow from "@/components/Table/TableRow";
import TableCell from "@/components/Table/TableCell";

import Accordion from "@/components/ui/Accourdion/Accordion";
import SvgIcon from "@/components/ui/SvgIcon";
import AppBadge from "@/components/ui/AppBadge";
import Card from "@/components/ui/Card/Card";
import AppInput from "@/components/Form/AppInput";
import { getId } from "@/utills/getId";
import AutosizeTextarea from "@/components/Form/AutosizeTextarea";
import UserInfo from "@/components/UserInfo/UserInfo";
import Btn from "@/components/ui/Buttons/Button";
import TextErrorMessage from "@/components/Errors/TextErrorMessage";

export default {
  name: "PaymentHistoryRow",
  components: {
    TextErrorMessage,
    Btn,
    AppInput,
    AppBadge,
    SvgIcon,
    Accordion,
    TableRow,
    TableCell,
    AutosizeTextarea,
    Card,
    UserInfo
  },
  props: {
    paymentDirection: {
      type: String,
      default: "default",
      validator: dir => ["incoming", "outgoing", "default"].indexOf(dir)
    },
    status: {
      type: String,
      validator: s => ["success", "rejected", "refunded"].indexOf(s),
      default: "success"
    },
    colsSizes: { type: Array, required: true },
    date: { type: String, required: true },
    icon: { type: String, required: true },
    iconBg: { type: String, default: "primary" },
    description: { type: String, required: true },
    type: { type: String, required: true },
    id: { type: String, required: true },
    amount: { type: String, required: true },
    badge: {
      type: Object,
      required: true,
      validator: b => "type" in b && "text" in b
    },

    p_id: { type: String, default: getId("payment") }
  },
  data() {
    return {
      detailsDescription:
        "Canada, Tourist – 5GB – 7 Days eSIM package purchase with FREE eSIM Profile",
      detailsStatus: "Success",
      detailsRecipientId: "09848764",
      detailsAmount: "12.99",
      detailsAuthorizationCode: "73BD91G",
      detailsPaymentType: "Credit Card ending **** 5642",
      detailsTransactionId: "618312966",
      detailDateOfPayment: "03.03.2020 9:14:20 pm GMT ",

      owner_source: "Telesim Admin Panel",
      owner_confirmation_code: "874194",
      owner_note:
        "User had issue with app. Called to support and asked to extend his number validity.",

      refund_amount: 0,
      refund_reason: ""
    };
  }
};
</script>

<style scoped lang="scss">
.icon-circle {
  font-size: 1.6rem;

  width: 1em;
  height: 1em;

  min-width: 1em;
  min-height: 1em;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    font-size: 0.4em;
    margin: 0;
  }
  &-primary {
    background-color: $color-primary-blue;
  }

  &-secondary {
    background-color: $color-success;
  }

  &-primary,
  &-secondary {
    .icon {
      color: $color-contrast-light;
    }
  }
}

.payment-type {
  &-default {
  }
  &-incoming {
    .payment-amount {
      color: $color-success;
    }
  }
  &-outgoing {
    color: $color-danger;
  }
}
</style>
