<template>
  <div
    class="virtual-number-footer d-flex align-items-center justify-content-between py-3"
  >
    <p class="secondary-text">Line is active from 20.03.2020 at 1:20 am GMT</p>
    <Btn type="danger-transparent" size="md">Delete number</Btn>
  </div>
</template>

<script>
import Btn from "@/components/ui/Buttons/Button";
export default {
  name: "VirtualNumberFooter",
  components: { Btn }
};
</script>

<style scoped></style>
