<template>
  <div class="d-flex package-progress">
    <div class="progressbar">
      <CircleProgressbar v-bind="$attrs" :total="total" :count="count" />
    </div>
    <div class=" mt-3 w-100 package-progress-description">
      <span v-if="title" v-html="title" />
      <div v-else class="d-flex">
        <strong>
          <b>{{ count }}</b>
        </strong>
        <small class="px-2">out of</small>
        <strong>
          <b>{{ 50 }}</b>
        </strong>
      </div>
      <p class="secondary-text" v-html="description" />
    </div>
  </div>
</template>
<script>
import CircleProgressbar from "@/components/ui/CircleProgressbar";

export default {
  name: "PackageProgress",
  components: { CircleProgressbar },
  props: {
    total: { type: Number, required: true },
    count: { type: Number, required: true },
    title: { type: String, required: false },
    description: { type: String, required: true }
  }
};
</script>

<style scoped lang="scss">
.progressbar {
  width: 4.2em;
  height: 4.2em;
}

.package-progress-description {
  margin-left: 1.4em;
}
</style>
