// warn you must provide [open, close] emits to <AppCollapsable/> component
// warn for example
// <AppCollapsable
//   collapsed-height="880"
//   @open="collapseToggle(false)"
//   @close="collapseToggle(true)"
//   :initial-opened="!collapsed"
// </AppCollapsable>

export const useCollapsedWithAccordion = {
  data() {
    return {
      collapsed: true,
      accordionOpened: false
    };
  },
  watch: {
    accordionOpened(isOpened) {
      // this.collapseToggle(!isOpened);
      if (isOpened) {
        setTimeout(() => {
          this.collapsed = false;
        }, 50);
      }
    }
  },
  methods: {
    collapseToggle(status) {
      this.collapsed = status;
      // let timeoutSeconds = !status ? 50 : 0;
      //
      // setTimeout(() => {
      //   this.collapsed = status;
      // }, timeoutSeconds);
    }
  },

  provide() {
    return {
      onAccordionOpen: () => (this.accordionOpened = true),
      onAccordionClose: () => (this.accordionOpened = false)
    };
  }
};
