<template>
  <Sidebar
    title="Credit balance history"
    :opened="balanceModalOpened"
    @hide="toggleBalanceModal(false)"
  >
    <template #heading>
      <AppCalendar />
    </template>

    <div class="d-flex justify-content-center py-4 mb-2">
      <AppBadge>Yesterday</AppBadge>
    </div>
    <ul class="balance-history-list">
      <li
        v-for="({ type, date, id, title, serviceType, amount },
                idx) in transactions"
        :key="id"
        class="balance-history-list--item"
        @click="openTransactionModal(`transaction-id-${idx}`, type)"
      >
        <div class="row align-items-center">
          <div class="col-11">
            <AppTransaction
              :title="title"
              :date="date"
              :service-type="serviceType"
              :type="type"
              :amount="amount"
            />
          </div>

          <div class="col-1">
            <SvgIcon
              class="d-block ms-auto chevron-icon"
              icon="chevron-right"
            />
          </div>
        </div>

        <hr v-if="idx !== transactions.length - 1" />
      </li>
    </ul>
  </Sidebar>
  <TransactionDetailsModal
    :type="openedTransactionType"
    :opened="transactionModalOpened"
    :on-back="backTransactionModal"
    :inserted="true"
    transaction-title="M credit package 17.5$ credit, (price 25$)"
    description="Credits on balance with M credit package for $25"
    date="01 April 2020 at 2:51 am GMT"
    amount="$17.50"
    balance-after-transaction="$89.71"
    transaction-id="1234"
    @hide="toggleTransactionModal(false)"
    @sendReceipt="onSendReceipt"
  />
  <div class="user-balance content-box">
    <img :src="require('@/assets/img/money.svg')" alt="bg" />
    <div class="user-balance-wrapper">
      <h2>{{ title }}</h2>
      <h3 class="balance mt-5">
        <span class="currency-symbol">{{ currencySymbol }}</span> {{ balance }}
      </h3>
    </div>

    <div class="overflow-button">
      <Btn type="primary" size="sm" @click="balanceModalOpened = true">
        Balance History
      </Btn>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/ui/Buttons/Button";
import Sidebar from "@/components/ui/Modal/Sidebar/Sidebar";
import AppCalendar from "@/components/ui/Calendar/AppCalendar";
import AppTransaction from "@/components/BalanceHistory/AppTransaction";
import SvgIcon from "@/components/ui/SvgIcon";
import AppBadge from "@/components/ui/AppBadge";
import TransactionDetailsModal from "@/components/BalanceHistory/TransactionDetailsModal";
import {
  serviceCall,
  servicePackage,
  serviceSms
} from "@/constants/serviceTypes";
import { incoming, outgoing } from "@/constants/transactionTypes";
import { getId } from "@/utills/getId";

export default {
  name: "UserBalance",
  components: {
    TransactionDetailsModal,
    AppBadge,
    SvgIcon,
    AppTransaction,
    AppCalendar,
    Sidebar,
    Btn
  },
  props: {
    title: { type: String, default: "Credit Balance" },
    balance: { type: String, required: true },
    currencySymbol: { type: String, default: "$" }
  },
  data() {
    return {
      balanceModalOpened: false,
      transactionModalOpened: false,
      openedTransactionType: null,
      transactions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((transaction, idx) => ({
        ...{
          id: getId(),
          title: "Outbound call",
          amount: "$0,0014",
          date: {
            date: "22 October 2020 ",
            time: "at 7:02 am GMT"
          }
        },
        ...transaction,
        serviceType:
          idx === 1 ? serviceCall : idx === 3 ? servicePackage : serviceSms,
        type: idx % 5 === 0 ? outgoing : incoming
      }))
    };
  },

  methods: {
    toggleBalanceModal(status = true) {
      this.balanceModalOpened = status;
    },

    toggleTransactionModal(status = true, type) {
      this.transactionModalOpened = status;
      this.openedTransactionType = status ? type : null;
    },

    openTransactionModal(id = "", type) {
      this.toggleBalanceModal(false);
      this.$nextTick(() => {
        this.toggleTransactionModal(true, type);
      });
    },

    backTransactionModal() {
      this.toggleBalanceModal(true);
      this.toggleTransactionModal(false);
    },

    onSendReceipt(id) {
      console.log("Send receipt by transaction id", id);
    }
  }
};
</script>

<style lang="scss">
$imageWidth: 233px;
.user-balance {
  position: relative;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 100%;
    width: auto;
    object-fit: contain;
  }
}

.user-balance-wrapper {
  min-width: 286px;
  width: calc(100% - #{$imageWidth});

  .balance {
    font-size: 4.3em;

    .currency-symbol {
      font-size: 0.67em;
    }
  }
}

.balance-history-list--item {
  cursor: pointer;
  @media (any-hover: hover) {
    .chevron-icon {
      transition: 0.25s;
    }
    &:hover {
      .chevron-icon {
        transform: translateX(3px);
      }
    }
  }
}
</style>
