<template>
  <div class="content-box px-0 translation-packages">
    <div class="padding-inner py-0">
      <h2>Translation Packages</h2>
    </div>

    <div class="mt-3">
      <div class="newsletter-preferences-list padding-inner py-0">
        <SettingsItem
          v-for="settingsItem in settings"
          :id="settingsItem.id"
          :key="settingsItem.id"
          v-model="settingsItem.checked"
          :title="settingsItem.title"
          :description="settingsItem.description"
          :disabled="isDisabled"
        />

        <SettingsItem
          id="settings:turn-off-all"
          v-model="turnOffAll"
          title="Turn Off all emails"
          :description="null"
          color-scheme="danger"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SettingsItem from "@/components/Settings/SettingsItem";
export default {
  name: "NewsletterPreferences",
  components: { SettingsItem },
  data() {
    return {
      settings: [
        {
          id: "settings:updates",
          checked: true,
          title: "Product Updates",
          description:
            "Important info on new features and products. We may also ask for your feedback on occasion."
        },
        {
          id: "settings:reports",
          checked: true,
          title: "Weekly Progress Reports",
          description:
            "Get a weekly report with statistics and insights on how you are using Telesim."
        },
        {
          id: "settings:telesim-offers",
          checked: false,
          title: "Telesim Offers",
          description:
            "These emails may include special upgrade offers, limited-time events, or coupons."
        },
        {
          id: "settings:telesim-blog",
          checked: false,
          title: "The Telesim Blog",
          description:
            "A weekly newsletter that includes fun tips on how to use the app."
        }
      ],
      turnOffAll: false
    };
  },

  computed: {
    isDisabled() {
      return this.turnOffAll;
    }
  },
  watch: {
    turnOffAll() {
      if (this.turnOffAll) {
        this.turnOffAllSettings();
      }
    }
  },
  methods: {
    turnOffAllSettings() {
      this.settings.forEach(settingsItem => {
        settingsItem.checked = false;
      });
    }
  }
};
</script>

<style scoped></style>
