<template>
  <span
    class="d-inline-flex  phone-country"
    :class="{
      'align-items-center': !description
    }"
  >
    <CountryIcon :id="countryId" class="me-2" />

    <span class="flex flex-column">
      <strong>{{ number }}</strong>
      <span v-if="description" class="secondary-text">{{ description }}</span>
    </span>
  </span>
</template>

<script>
import CountryIcon from "@/components/ui/Country/CountryIcon";
export default {
  name: "CountryWithPhone",
  components: { CountryIcon },
  props: {
    countryId: { type: String, required: true },
    number: { type: String, required: true },
    description: { type: String, required: false }
  }
};
</script>

<style scoped>
.phone-country strong {
  white-space: nowrap;
}
</style>
