<template>
  <Card title="NUMBER Actions" class="number-actions-card">
    <div class="settings-item-general">
      <SettingsItem
        :id="`virtual-numbers:${id()}`"
        v-model="lineStatus"
        title="Line Status"
        description="If OFF, calls will be forwarded  to Voicemail."
      />
    </div>
    <SettingsItem
      :id="`virtual-numbers:${id()}`"
      v-model="defaultCallerId"
      title="Default caller ID"
    />

    <SettingsItem
      :id="`virtual-numbers:${id()}`"
      v-model="automaticCallRecording"
      title="Automatic call recording"
    />
    <SettingsItem
      :id="`virtual-numbers:${id()}`"
      v-model="voicemail"
      title="Voicemail"
    />

    <SettingsItem
      :id="`virtual-numbers:${id()}`"
      v-model="callForwarding"
      title="Call forwarding "
    />
  </Card>
</template>

<script>
import Card from "@/components/ui/Card/Card";
import SettingsItem from "@/components/Settings/SettingsItem";
import { getId } from "@/utills/getId";
export default {
  name: "NumberActionsCard",
  components: { SettingsItem, Card },
  data() {
    return {
      lineStatus: false,
      defaultCallerId: false,
      automaticCallRecording: false,
      voicemail: false,
      callForwarding: false
    };
  },

  methods: {
    id() {
      return getId("check");
    }
  }
};
</script>

<style scoped lang="scss">
.settings-item {
  padding-top: 6px;
  padding-bottom: 6px;
}
.settings-item-general {
  margin-bottom: 9px;
  .settings-item:last-child {
    padding-bottom: 15px;
  }
  & ~ .settings-item {
    border: none;
  }
}
</style>
