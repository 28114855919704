<template>
  <div class="file " v-bind="$attrs">
    <svg-icon icon="file" class="file-icon" />
    <strong>
      <b>{{ name }}</b>
    </strong>
    <button v-if="!readonly" class="file-remove" @click="$emit('remove')">
      <svg-icon icon="remove" />
    </button>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "FileItem",
  components: { SvgIcon },
  props: {
    name: { type: String, required: true },
    readonly: { type: Boolean, default: false }
  },
  emits: ["remove"]
};
</script>

<style lang="scss">
$removeBtnSize: 1.42em;
$removeBtnIndent: 1em;
.file {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  height: 36px;

  .icon {
    margin-bottom: 0;
  }

  @extend %flex-center;
  strong {
    font: inherit;
    max-width: calc(100% - #{$removeBtnSize} - #{$removeBtnIndent} - 2em);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-icon {
    color: $color-danger;
    font-size: 1.2em;
    margin-right: 0.7em;
  }

  .file-remove {
    width: $removeBtnSize;
    height: $removeBtnSize;
    border-radius: 50%;
    background-color: #f2f2f2;
    margin-left: 1em;
    @extend %flex-center;
    .icon {
      font-size: 0.5em;
      fill: $grey-100;
    }
  }
}
</style>
