<template>
  <form class="align-self-start pb-5" @submit.prevent>
    <h4>Submission Form</h4>
    <div class="row mt-4 pt-2">
      <div class="col-6">
        <AppInput
          id="verificаtion:fullname"
          v-model="fullName"
          label="Full Name"
          input-type="material"
        />
      </div>
      <div class="col-6">
        <AppInput
          id="verificаtion:address"
          v-model="address"
          label="Address"
          input-type="material"
        />
      </div>

      <div class="col-6 mt-3">
        <AppInput
          id="verificаtion:city"
          v-model="city"
          label="City"
          input-type="material"
        />
      </div>
      <div class="col-6 mt-3">
        <AppInput
          id="verificаtion:state"
          v-model="state"
          label="State/Province"
          input-type="material"
        />
      </div>

      <div class="col-6 mt-3">
        <AppInput
          id="verificаtion:zip"
          v-model="zip"
          label="Zip Code"
          input-type="material"
        />
      </div>
      <div class="col-6 mt-3">
        <AppInput
          id="verificаtion:country"
          v-model="country"
          label="Country"
          input-type="material"
        />
      </div>

      <div class="col-6 mt-3">
        <UploadArea
          title="Upload ID"
          accept="image/x-png,image/pdf,image/jpeg"
          description="Can be electric bill, gas, phone, bank. File type – PNG, JPEG or PDF. Size limit - 2Mb."
        />
      </div>
      <div class="col-6 mt-3">
        <UploadArea
          title="Upload Proof of Address"
          accept="image/x-png,image/pdf,image/jpeg"
          description="Can be electric bill, gas, phone, bank. File type – PNG, JPEG or PDF. Size limit - 2Mb."
        />
      </div>
    </div>

    <Btn
      type="secondary"
      size="xl"
      class="verification-button mt-5"
      @click="$emit('submit')"
    >
      Save
    </Btn>
  </form>
</template>

<script>
import AppInput from "@/components/Form/AppInput";
import Btn from "@/components/ui/Buttons/Button";
import UploadArea from "@/components/Form/Files/UploadArea";
export default {
  name: "Submission",
  components: { UploadArea, Btn, AppInput },
  emits: ["submit"],
  data() {
    return {
      fullName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: ""
    };
  }
};
</script>

<style scoped></style>
