
import BackButton from "@/components/ui/Buttons/BackButton.vue";
import UserBalance from "@/components/User/UserBalance.vue";
import AccountStatus from "@/components/User/AccountStatus.vue";
import AppCollapsable from "@/components/ui/Collapsable/AppCollapsable.vue";
import AppSecurity from "@/components/User/AppSecurity/AppSecurity.vue";
import VirtualNumbersTable from "@/components/User/VirtualNumbers/VirtualNumbersTable.vue";
import EsimProfilesTable from "@/components/User/eSIMProfiles/EsimProfilesTable.vue";
import TranslationPackages from "@/components/User/TranslationPackages/TranslationPackages.vue";
import NewsletterPreferences from "@/components/User/NewsletterPreferences/NewsletterPreferences.vue";
import NumberUsageModal from "@/components/User/VirtualNumbers/NumberUsageModal/NumberUsageModal.vue";
import DeleteUserDialog from "@/components/ui/Modal/Dialog/instances/DeleteUserDialog.vue";
import AccountInfo from "@/components/User/AccountInfo.vue";
import { mapGetters, useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, watch, defineComponent } from "vue";
import { useAccount } from "@/hooks/admin/useAdmin";
import { usePayments } from "@/hooks/payments/usePayments";
import PaymentHistoryTable from "@/components/User/PaymentHistory/PaymentHistoryTable.vue";
import NotesBlock from "@/components/Notes/NotesBlock.vue";
import { useNotes } from "@/hooks/notes/useNotes";
import { CreateNoteDto } from "@/store/interfaces/notes/CreateNote.dto";
import InDeveloping from "@/components/InDeveloping/InDeveloping.vue";
import FullscreenLoader from "@/components/ui/FullscreenLoader.vue";

interface UserEditData {
  email: string;
  fullName: string;
  id: number;
}

export default defineComponent({
  name: "User",
  components: {
    FullscreenLoader,
    InDeveloping,
    NotesBlock,
    PaymentHistoryTable,
    AccountInfo,
    DeleteUserDialog,
    NumberUsageModal,
    NewsletterPreferences,
    TranslationPackages,
    EsimProfilesTable,
    VirtualNumbersTable,
    AppSecurity,
    AppCollapsable,
    AccountStatus,
    BackButton,
    UserBalance
  },
  provide() {
    return {
      userId: this.userId,
      openUsageLogsModal: this.showUsageLogsModal
    };
  },
  setup() {
    const store = useStore();
    const router = useRoute();

    const viewConfiguration: { page: number; perPage: number } = {
      page: 1,
      perPage: 1000
    };
    const userId = Number(router.query.userId);
    const options = { viewConfiguration, userId };
    const paymentViewConfig = {
      paymentType: "All",
      status: "All",
      date: "",
      page: 1,
      perPage: 10
    };
    const filter = {
      type: "",
      from: "",
      to: ""
    };
    const paymentOptions = {
      viewConfig: paymentViewConfig,
      filter
    };
    const {
      getUserInfo,
      getLoginHistory,
      removeUser,
      uploadAvatar,
      editUser,
      getPaymentHistoryById
    } = useAccount(options);
    const { groupByDate, formatDate } = usePayments(paymentOptions);
    const notesOptions = {
      query: {
        hold: false,
        take: 10,
        skip: 0
      },
      id: userId
    };
    const {
      getNotes,
      getHoldNotes,
      addNote,
      changeNoteStatus,
      removeNote
    } = useNotes(notesOptions);

    // Watch routers
    watch(router, route => {
      let { path } = route;
      if (path === "/user") {
        getUserInfo();
      }
    });
    // Call functions
    store.dispatch("setAppLoading", true);
    Promise.all([
      getNotes(),
      getUserInfo(), // fetch accounts on page created
      getLoginHistory(viewConfiguration), // fetch login history on page created
      getPaymentHistoryById(),
      getHoldNotes()
    ]).then(() => {
      store.dispatch("setAppLoading", false);
    });

    return {
      userInformation: computed(() => store.getters.userInformation),
      userLogins: computed(() => store.getters.userLogins),
      paymentsList: computed(() => store.getters.userPaymentList),
      paymentsTotal: computed(() => store.getters.totalPayment),
      notesList: computed(() => store.getters.notesList),
      totalNotes: computed(() => store.getters.totalNotes),
      notesHoldList: computed(() => store.getters.notesHoldList),
      totalNotesHold: computed(() => store.getters.totalNotesHold),
      addNote,
      getUserInfo,
      getNotes,
      getHoldNotes,
      changeNoteStatus,
      removeNote,
      removeUser,
      uploadAvatar,
      editUser,
      formatDate,
      userId,
      groupByDate,
      paymentViewConfig
    };
  },
  data: () => {
    return {
      name: "Sophie Turner",
      usageLogsActiveModalId: null as null | number,
      deleteUserDialogShown: false,
      isLoading: false,
      useLoader: true,
      editData: {
        email: "",
        fullName: ""
      } as UserEditData
    };
  },
  computed: {
    ...mapGetters(["appLoading"]),
    accountInfo() {
      return this.userInformation;
    },
    newData() {
      return this.groupByDate(this.paymentsList);
    },
    userName() {
      return this.userInformation?.["fullName"];
    },
    emailVerified(): boolean | null | undefined {
      return this.userInformation?.["emailVerified"];
    },
    blocked(): boolean | null | undefined {
      return this.userInformation?.["blocked"];
    }
  },
  watch: {
    userInformation: {
      handler(data) {
        this.editData.email = data.email;
        this.editData.fullName = data.fullName;
        this.editData.id = this.userId;
      },
      deep: true
    }
  },
  methods: {
    showUsageLogsModal(id: string | undefined) {
      if (typeof id === "string") {
        this.usageLogsActiveModalId = this.userId;
      } else {
        throw new Error(`${id} - is not valid number id`);
      }
    },
    cleanData() {
      this.deleteUserDialogShown = false;
    },
    hideUsageLogsModal() {
      this.usageLogsActiveModalId = null;
    },

    onUserDelete() {
      this.deleteUserDialogShown = true;
    },
    async removeUserHandler() {
      this.isLoading = true;
      const success = await this.removeUser();
      if (success) {
        this.cleanData();
        this.$router.back();
      }
      this.isLoading = false;
    },
    async createNote(data: CreateNoteDto) {
      const success = await this.addNote(data);
      if (success) {
        if (data.hold) {
          await this.getHoldNotes();
        } else {
          await this.getNotes();
        }
      }
    },
    async changeNoteStatusHandler(id: number, noteId: number, status: boolean) {
      const success = await this.changeNoteStatus(id, noteId, status);
      if (success) {
        await this.getNotes();
        await this.getHoldNotes();
      }
    },
    async removeNoteHandler(id: number, noteId: number) {
      const success = await this.removeNote(id, noteId);
      if (success) {
        await this.getNotes();
        await this.getHoldNotes();
      }
    }
  }
});
