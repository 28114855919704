<template>
  <LogsTable title="Call Logs">
    <template #toolbar>
      <div class="page-heading-toolbar--col me-3">
        <type-select :options="callTypes" />
      </div>
      <div class="page-heading-toolbar--col me-3"><AppCalendar /></div>
      <div class="page-heading-toolbar--col">
        <SelectDropdown
          id="dd-phones"
          :list="ddNumbers"
          :on-select="numberSelectHandler"
          class="phone-select"
        />
      </div>
    </template>

    <template #table>
      <TableView class="no-shadow ">
        <template #table-head>
          <TableHeadCell id="call-logs:to" name="to" size="md" />
          <TableHeadCell id="call-logs:from" name="from" size="lg" />
          <TableHeadCell
            id="call-logs:dateTime"
            name="Date & time"
            size="smL"
            sort="a"
            :on-sort="() => {}"
          />
          <TableHeadCell
            id="call-logs:duration"
            name="Duration"
            size="smL"
            sort="a"
            :on-sort="() => {}"
          />

          <TableHeadCell
            id="call-logs:rate"
            name="Rate / Total"
            size="smL"
            sort="a"
            :on-sort="() => {}"
          />

          <TableHeadCell id="call-logs:forward" name="Forward" size="md" />
          <TableHeadCell id="call-logs:record" name="Record" size="md" />
        </template>

        <template #table-body>
          <TableRow v-for="n in 20" :key="n">
            <template #content>
              <TableCell size="md">
                <CountryWithPhone country-id="esp" number="+34 0614541 871" />
              </TableCell>
              <TableCell size="lg">
                <CountryWithPhone
                  country-id="usa"
                  number="+34 0614541 871"
                  description="Business"
                />
              </TableCell>

              <TableCell size="smL">
                <p>
                  <strong>02.05.2020</strong>
                  <span class="secondary-text d-block">11:38:56 pm GMT</span>
                </p>
              </TableCell>

              <TableCell size="smL">
                <strong>00:04:17</strong>
              </TableCell>
              <TableCell size="smL">
                <strong>¢12 / $1.09</strong>
              </TableCell>
              <TableCell size="md">
                <CountryWithPhone
                  v-if="(n === 3) !== n > 17"
                  country-id="usa"
                  number="+1 002 6783 190"
                  description="Home"
                />
                <Status v-else :status="false" />
              </TableCell>

              <TableCell size="md">
                <Status :status="(n === 1) !== n > 12 && n < 16" />
              </TableCell>
            </template>
          </TableRow>
        </template>

        <template #table-footer>
          <TableFooter :pagination="{ total: 100, current: 1 }" />
        </template>
      </TableView>
    </template>
  </LogsTable>
</template>

<script>
import LogsTable from "@/components/User/LogsTable";
import TypeSelect from "@/components/ui/TypeSelect/TypeSelect";
import AppCalendar from "@/components/ui/Calendar/AppCalendar";
import TableView from "@/components/Table/TableView";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown";
import TableHeadCell from "@/components/Table/TableHeadCell";
import TableRow from "@/components/Table/TableRow";
import TableCell from "@/components/Table/TableCell";
import CountryWithPhone from "@/components/ui/Country/CountryWithPhone";
import Status from "@/components/ui/Status";
import TableFooter from "@/components/Table/TableFooter";
export default {
  name: "CallLogs",
  components: {
    TableFooter,
    Status,
    CountryWithPhone,
    TableCell,
    TableRow,
    TableHeadCell,
    SelectDropdown,
    TableView,
    AppCalendar,
    TypeSelect,
    LogsTable
  },
  data() {
    return {
      callTypes: [
        {
          id: "callTypes:Inbound",
          value: "Inbound",
          // icon: require("@/assets/img/icons/inbound.svg"),
          icon: "inbound"
        },
        {
          id: "callTypes:Outbound",
          value: "Outbound",
          // icon: require("@/assets/img/icons/outbound.svg"),
          icon: "outbound"
        },
        {
          id: "callTypes:Missed",
          value: "Missed",
          // icon: require("@/assets/img/icons/missed.svg"),
          icon: "missed"
        }
      ],
      ddNumbers: [
        {
          name: "all",
          selected: true,
          id: "all",
          html: `<span>All numbers</span>`
        },
        {
          name: "usa",
          selected: false,
          id: "usa",
          html: `
            <div class="d-flex align-items-center">
              <div class="country-icon me-2">
                <img src="${require("@/assets/img/carrirsCountries/usa.svg")}" alt="usa" />
              </div>

              <span>+1 002 6783 190 </span>
            </div>
          `
        },
        {
          name: "esp",
          selected: false,
          id: "esp",
          html: `
            <div class="d-flex align-items-center">
              <div class="country-icon me-2">
                <img src="${require("@/assets/img/carrirsCountries/esp.svg")}" alt="usa" />
              </div>

              <span>+34 0614541871 </span>
            </div>
          `
        }
      ]
    };
  },
  methods: {
    numberSelectHandler(id) {
      this.ddNumbers.forEach(n => {
        n.selected = n.id === id;
      });
    }
  }
};
</script>

<style>
.phone-select {
  width: 202px;
}
</style>
