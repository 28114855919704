
import Chat from "../Chat/Chat.vue";
import SvgIcon from "../ui/SvgIcon.vue";
import Indicator from "../ui/Indicator.vue";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { INotes } from "@/api/interfaces/notes/common";

export default defineComponent({
  name: "NotesBlock",
  components: { Indicator, SvgIcon, Chat },
  emits: {
    createNote: null,
    removeNote: null,
    changeNoteStatus: null
  },
  data() {
    return {
      hold: false
    };
  },
  props: {
    notesData: {
      type: Array as PropType<INotes[]>,
      required: true
    },
    notesTotal: {
      type: Number,
      required: true
    },
    holdNotesData: {
      type: Array as PropType<INotes[]>,
      required: true
    },
    holdNotesTotal: {
      type: Number,
      required: true
    },
    formatDate: {
      type: Function,
      required: true
    },
    groupByDate: {
      type: Function,
      required: true
    }
  },
  computed: {
    mappedNotesData() {
      return this.groupByDate(
        this.notesData.map(data => ({
          date: data.added,
          msgId: data.id,
          isMe: false,
          msg: data.message,
          time: this.formatDate(data.added),
          senderName: data.addedBy.fullName,
          senderPhoto: data.addedBy.image,
          senderRole: {
            icon: data.addedBy.role.title,
            name: `TELESIM ${data.addedBy.role.title}`
          },
          actions: [
            {
              text: "Change hold status",
              onClick: (): void => {
                this.changeStatusHandler(data.addedBy.id, data.id, true);
              }
            },
            {
              text: "Remove",
              cls: "danger",
              onClick: (): void => {
                this.deleteHandler(data.addedBy.id, data.id);
              }
            }
          ]
        }))
      );
    },
    mappedHoldNotesData() {
      return this.groupByDate(
        this.holdNotesData.map(data => ({
          date: data.added,
          msgId: data.id,
          isMe: false,
          msg: data.message,
          time: this.formatDate(data.added),
          senderName: data.addedBy.fullName,
          senderPhoto: data.addedBy.image,
          senderRole: {
            icon: data.addedBy.role.title,
            name: `TELESIM ${data.addedBy.role.title}`
          },
          actions: [
            {
              text: "Change hold status",
              onClick: (): void => {
                this.changeStatusHandler(data.addedBy.id, data.id, false);
              }
            },
            {
              text: "Remove",
              cls: "danger",
              onClick: (): void => {
                this.deleteHandler(data.addedBy.id, data.id);
              }
            }
          ]
        }))
      );
    }
  },
  methods: {
    createNote(message: string) {
      this.$emit("createNote", { hold: this.hold, message });
    },
    changeStatusHandler(addedById: number, noteId: number, status: boolean) {
      this.$emit("changeNoteStatus", addedById, noteId, status);
    },
    deleteHandler(id: number, noteId: number) {
      this.$emit("removeNote", id, noteId);
    }
  }
});
