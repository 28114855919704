
import TableRow from "@/components/Table/TableRow.vue";
import Accordion from "@/components/ui/Accourdion/Accordion.vue";
import EsimProfilesRowHeading from "@/components/User/eSIMProfiles/EsimProfilesRowHeading.vue";
import EsimProfileRowBody from "@/components/User/eSIMProfiles/EsimProfileRowBody.vue";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import {
  IEsimBalance,
  IEsimData
} from "@/api/services/esim/profile/profile.types";
import { IPackage } from "@/api/services/packages/esim/location-packages/types";

export default defineComponent({
  name: "EsimProfilesTableRow",
  components: {
    EsimProfileRowBody,
    EsimProfilesRowHeading,
    Accordion,
    TableRow
  },
  props: {
    activeFrom: {
      type: String,
      required: true
    },
    activeTo: {
      type: String,
      required: true
    },
    balance: {
      type: Object as PropType<IEsimBalance>,
      required: true
    },
    device: {
      type: String,
      required: true
    },
    esim: {
      type: Object as PropType<IEsimData>,
      required: true
    },
    profileId: {
      type: Number,
      required: true
    },
    installed: {
      type: Boolean,
      required: true
    },
    esimPackage: {
      type: Object as PropType<IPackage>,
      required: true
    },
    profileName: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {}
});
