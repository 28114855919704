<template>
  <div class="row w-100 verification-end pt-5">
    <div class="col-6">
      <h4 class="subtitle">SUBMISSION FORM</h4>
      <div class="mt-4">
        <strong>
          Verification details has been <br />
          successfully uploaded!
        </strong>
      </div>

      <div class="mt-4 pt-3">
        <ul class="verification-files">
          <li>
            <FileItem
              :readonly="true"
              name="Government ID.pdf"
              class="justify-content-start"
            />
          </li>
          <li>
            <FileItem
              :readonly="true"
              name="Address Verification.pdf"
              class="justify-content-start"
            />
          </li>
        </ul>
      </div>

      <div class="mt-5">
        <Btn type="primary" size="xl" class="verification-button">
          Edit Details
        </Btn>

        <Btn
          type="secondary"
          size="xl"
          class="verification-button mt-2"
          :disabled="true"
        >
          Send to Multitel
        </Btn>
      </div>
    </div>
    <div class="col-6">
      <h4 class="subtitle">Status</h4>
      <div class="mt-5">
        <StatusSteps :steps-list="steps" />
      </div>
    </div>
  </div>
</template>

<script>
import FileItem from "@/components/Form/Files/FileItem";
import Btn from "@/components/ui/Buttons/Button";
import StatusSteps from "@/components/ui/Statuses/StatusSteps";
export default {
  name: "VerificationEnd",
  components: { StatusSteps, Btn, FileItem },
  data() {
    return {
      steps: [
        {
          title: "Form Submission",
          description: "02.05.2020 at 11:38:56 pm GMT",
          status: "success"
        },
        {
          title: "Multitel Review",
          description: "02.05.2020 at 12:41:05 pm GMT",
          status: "progress"
        },
        {
          title: "Final step",
          status: "wait"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.verification-end {
  .file {
    height: auto;
    font-size: 13px;
    text-decoration: underline;
  }

  .verification-files {
    & > li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
