<template>
  <div class="padding-inner py-0 ">
    <h2 class="mb-4">Favourites</h2>
  </div>
  <div class="mt-5">
    <TableView
      :with-more-menu="true"
      class="favorites-table accordion-table mt-4 user-table favourites-table no-shadow"
    >
      <template #table-head>
        <TableHeadCell
          id="favourites:name"
          name="Contact Name"
          size="lg"
          :on-sort="onSort"
          sort="a"
        />

        <TableHeadCell id="favourites:number" name="Number" size="md" />
        <TableHeadCell id="favourites:label" name="Label" size="md" />
        <TableHeadCell
          id="favourites:date"
          name="Date added"
          size="lg"
          :on-sort="onSort"
          sort="a"
        />
      </template>

      <template #table-body>
        <TableRow :more-menu="[{ text: 'Remove', cls: 'danger' }]">
          <template #content>
            <TableCell size="lg">
              <UserInfo
                :avatar="require('@/assets/img/sophie.png')"
                name="Hirouki Takeda"
              />
            </TableCell>

            <TableCell size="md">
              <Country id="usa" name="+1 092 6784 887" />
            </TableCell>

            <TableCell size="md">
              <p>Business</p>
            </TableCell>

            <TableCell size="lg">
              <p>
                02.05.2020 <br />
                <span class="secondary-text"> 11:38:56 pm GMT</span>
              </p>
            </TableCell>
          </template>
        </TableRow>
        <TableRow :more-menu="[{ text: 'Remove', cls: 'danger' }]">
          <template #content>
            <TableCell size="lg">
              <UserInfo
                :avatar="require('@/assets/img/walter.png')"
                name="Walter H. White"
              />
            </TableCell>

            <TableCell size="md">
              <Country id="esp" name="1 092 6784 887" />
            </TableCell>

            <TableCell size="md">
              <p>Business</p>
            </TableCell>

            <TableCell size="lg">
              <p>
                02.05.2020 <br />
                <span class="secondary-text"> 11:38:56 pm GMT</span>
              </p>
            </TableCell>
          </template>
        </TableRow>
        <TableRow :more-menu="[{ text: 'Remove', cls: 'danger' }]">
          <template #content>
            <TableCell size="lg">
              <UserInfo name="Walter H. White" />
            </TableCell>

            <TableCell size="md">
              <Country id="can" name="1 092 6784 887" />
            </TableCell>

            <TableCell size="md">
              <p>Business</p>
            </TableCell>

            <TableCell size="lg">
              <p>
                02.05.2020 <br />
                <span class="secondary-text"> 11:38:56 pm GMT</span>
              </p>
            </TableCell>
          </template>
        </TableRow>

        <TableRow :more-menu="[{ text: 'Remove', cls: 'danger' }]">
          <template #content>
            <TableCell size="lg">
              <UserInfo name="Walter H. White" />
            </TableCell>

            <TableCell size="md">
              <Country id="can" name="1 092 6784 887" />
            </TableCell>

            <TableCell size="md">
              <p>Business</p>
            </TableCell>

            <TableCell size="lg">
              <p>
                02.05.2020 <br />
                <span class="secondary-text"> 11:38:56 pm GMT</span>
              </p>
            </TableCell>
          </template>
        </TableRow>

        <TableRow :more-menu="[{ text: 'Remove', cls: 'danger' }]">
          <template #content>
            <TableCell size="lg">
              <UserInfo name="Walter H. White" />
            </TableCell>

            <TableCell size="md">
              <Country id="can" name="1 092 6784 887" />
            </TableCell>

            <TableCell size="md">
              <p>Business</p>
            </TableCell>

            <TableCell size="lg">
              <p>
                02.05.2020 <br />
                <span class="secondary-text"> 11:38:56 pm GMT</span>
              </p>
            </TableCell>
          </template>
        </TableRow>

        <div class="pt-4 pb-5 padding-inner">
          <p class="secondary-text">2 contacts in Favourites</p>
        </div>
      </template>
    </TableView>
  </div>
</template>

<script>
import TableView from "@/components/Table/TableView";
import TableHeadCell from "@/components/Table/TableHeadCell";
import TableRow from "@/components/Table/TableRow";
import TableCell from "@/components/Table/TableCell";
import UserInfo from "@/components/UserInfo/UserInfo";
import Country from "@/components/ui/Country/Country";
export default {
  name: "VirtualNumberFavorites",
  components: {
    UserInfo,
    TableCell,
    TableRow,
    TableHeadCell,
    TableView,
    Country
  },
  methods: {
    onSort() {}
  }
};
</script>

<style lang="scss">
.favorites-table {
  .user-avatar {
    font-size: 3rem !important;
  }
}
</style>
