<template>
  <CircleProgressbar
    :stroke-width="5"
    :tail-width="2"
    :total="total"
    :count="count"
    v-bind="$attrs"
  >
    <template #content>
      <div class="lg-progressbar">
        <strong class="text-center">
          <b>{{ count }} {{ unit }}</b> <br />
          <span>out of {{ total }} {{ unit }} left</span>
        </strong>

        <span
          v-if="description"
          class="d-block secondary-text text-center mt-3"
          style="width: 100px;"
          v-html="description"
        />
      </div>
    </template>
  </CircleProgressbar>
</template>

<script>
import CircleProgressbar from "@/components/ui/CircleProgressbar";
export default {
  name: "PackageDataProgress",
  components: { CircleProgressbar },
  props: {
    count: { type: Number, required: true },
    total: { type: Number, required: true },
    unit: { type: String, default: "GB" },
    description: { type: String, required: false }
  }
};
</script>

<style scoped lang="scss">
.lg-progressbar {
  max-width: 180px;
  color: $color-contrast-dark;
  .secondary-text {
    line-height: 1.3;
    font-size: 0.714rem;
  }
  strong {
    font-size: 1rem;
    b {
      font-size: 1.7em;
      font-weight: 700;
    }
  }
}
</style>
