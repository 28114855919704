<template>
  <LogsTable title="FAX Logs">
    <template #toolbar>
      <div class="page-heading-toolbar--col me-3"><AppCalendar /></div>
      <div class="page-heading-toolbar--col">
        <SelectDropdown
          id="dd-phones"
          :list="ddNumbers"
          :on-select="numberSelectHandler"
          class="phone-select"
        />
      </div>
    </template>

    <template #table>
      <TableView class="no-shadow ">
        <template #table-head>
          <TableHeadCell id="call-logs:to" name="from" size="md" />
          <TableHeadCell id="call-logs:from" name="to" size="xl" />
          <TableHeadCell
            id="call-logs:dateTime"
            name="Date & time"
            size="smL"
            sort="a"
            :on-sort="() => {}"
          />
          <TableHeadCell
            id="call-logs:duration"
            name="Pages"
            size="sm"
            sort="a"
            :on-sort="() => {}"
          />

          <TableHeadCell
            id="call-logs:rate"
            name="Rate / Total"
            size="smL"
            sort="a"
            :on-sort="() => {}"
          />

          <TableHeadCell
            id="virtual-number:line-status"
            name="STATUS"
            size="md"
            class="right-align"
          />
        </template>

        <template #table-body>
          <TableRow v-for="n in 20" :key="n">
            <template #content>
              <TableCell size="md">
                <CountryWithPhone country-id="esp" number="+34 0614541 871" />
              </TableCell>
              <TableCell size="xl">
                <CountryWithPhone
                  country-id="usa"
                  number="+34 0614541 871"
                  description="Business"
                />
              </TableCell>

              <TableCell size="smL">
                <p>
                  <strong>02.05.2020</strong>
                  <span class="secondary-text d-block">11:38:56 pm GMT</span>
                </p>
              </TableCell>

              <TableCell size="sm">
                <strong>20</strong>
              </TableCell>
              <TableCell size="smL">
                <strong>¢12 / $1.09</strong>
              </TableCell>
              <TableCell size="md">
                <div class="d-flex justify-content-end w-100">
                  <AppBadge
                    :rounded="false"
                    :type="n === 2 ? 'danger' : 'success'"
                    size="md"
                  >
                    {{ n === 2 ? "Failed" : "Delivered" }}
                  </AppBadge>
                </div>
              </TableCell>
            </template>
          </TableRow>
        </template>

        <template #table-footer>
          <TableFooter :pagination="{ total: 100, current: 1 }" />
        </template>
      </TableView>
    </template>
  </LogsTable>
</template>

<script>
import LogsTable from "@/components/User/LogsTable";
import AppCalendar from "@/components/ui/Calendar/AppCalendar";
import TableView from "@/components/Table/TableView";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown";
import TableHeadCell from "@/components/Table/TableHeadCell";
import TableRow from "@/components/Table/TableRow";
import TableCell from "@/components/Table/TableCell";
import CountryWithPhone from "@/components/ui/Country/CountryWithPhone";
import TableFooter from "@/components/Table/TableFooter";
import AppBadge from "@/components/ui/AppBadge";
export default {
  name: "FaxLogs",
  components: {
    AppBadge,
    TableFooter,
    CountryWithPhone,
    TableCell,
    TableRow,
    TableHeadCell,
    SelectDropdown,
    TableView,
    AppCalendar,
    LogsTable
  },
  data() {
    return {
      ddNumbers: [
        {
          name: "all",
          selected: true,
          id: "all",
          html: `<span>All numbers</span>`
        },
        {
          name: "usa",
          selected: false,
          id: "usa",
          html: `
            <div class="d-flex align-items-center">
              <div class="country-icon me-2">
                <img src="${require("@/assets/img/carrirsCountries/usa.svg")}" alt="usa" />
              </div>

              <span>+1 002 6783 190 </span>
            </div>
          `
        },
        {
          name: "esp",
          selected: false,
          id: "esp",
          html: `
            <div class="d-flex align-items-center">
              <div class="country-icon me-2">
                <img src="${require("@/assets/img/carrirsCountries/esp.svg")}" alt="usa" />
              </div>

              <span>+34 0614541871 </span>
            </div>
          `
        }
      ]
    };
  },
  methods: {
    numberSelectHandler(id) {
      this.ddNumbers.forEach(n => {
        n.selected = n.id === id;
      });
    }
  }
};
</script>

<style>
.phone-select {
  width: 202px;
}
</style>
