import { useStore } from "vuex";

export function useUserEsimProfile() {
  const store = useStore();
  async function fetchUserEsimProfile(id: number) {
    const { success, message } = await store.dispatch(
      "fetchEsimProfilesList",
      id
    );

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
  }
  return {
    fetchUserEsimProfile
  };
}
